<template>
  <!--begin::Popup Smartfarm-->
  <div class="smartfarm-03 p-6">
    <span class="table-title font-weight-bold">{{i18n.title}}</span>
    <div class="mt-6">
      <v-data-table
          :headers="fields"
          :items="cropItems"
          :items-per-page="10"
          class="elevation-1 farm-data-table"
          no-data-text="No Data"
      >
        <template v-slot:[`item.cropCd`]="{ item }">
            {{ item.cropCd ? item.cropCd : '-' }}
        </template>
        <template v-slot:[`item.cropConnectNm`]="{ item }">
          {{ item.cropConnectNm ? item.cropConnectNm : '-' }}
        </template>
      </v-data-table>

<!--      <b-table -->
<!--        responsive-->
<!--        :items="cropItems"-->
<!--        :fields="fields"-->
<!--        head-variant="light"-->
<!--        class="table-vertical-center"-->
<!--      >-->
<!--      <template #table-colgroup="scope">-->
<!--        <col-->
<!--          v-for="field in scope.fields"-->
<!--          :key="field.key"-->
<!--          :style="{ width: field.key === 'idx' ? '70px' : 'auto' }"-->
<!--        >-->
<!--      </template>-->
<!--      </b-table>-->
    </div>
  </div>
  <!--end: Popup Smartfarm-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';
import {lan} from '@/core/services/funcs';

export default {
  name: "SmartFarm4",
  props: {
    cropInfo: Array
  },
  data() {
    return {
      i18n: {
        title: i18n.t('farm.cultivationCropMangement.title'),
        cropName: i18n.t('farm.cultivationCropMangement.cropName'),
        cropNumber: i18n.t('farm.cultivationCropMangement.cropNumber'),
        cultivationCropType: i18n.t('farm.cultivationCropMangement.cultivationCropType'),
        cultivationEnvStandard: i18n.t('farm.cultivationCropMangement.cultivationEnvStandard'),
        controllerConnection: i18n.t('farm.cultivationCrop.controllerConnection'),
      }
    };
  },
  computed: {
    cropItems() {
      if(this.cropInfo && this.cropInfo.length > 0)  return this.cropInfo;
      return [];
    },
    fields() {
      return [
        { value: lan() =='ko' ? 'cropNm' : 'cropNmEn', text: i18n.t('farm.cultivationCropMangement.cropName')},
        { value: 'cropCd', text: i18n.t('farm.cultivationCropMangement.cropNumber')},
        { value: lan() =='ko' ? 'cropType' : 'cropTypeEn', text: i18n.t('farm.cultivationCropMangement.cultivationCropType')},
        { value: 'baseNm', text: i18n.t('farm.cultivationCropMangement.cultivationEnvStandard')},
        { value: 'cropConnectNm', text: i18n.t('farm.cultivationCrop.controllerConnection')}
      ]
      }
  }
};
</script>