<template>
  <!--begin::Popup Smartfarm-->
  <div class="smartfarm-01 py-6 px-0 px-lg-6">
    <ul class="text-group row list-unstyled">
      <li class="col-12">
        <div class="row">
        <!--begin::팜 주소-->
          <dt class="title col-12 col-lg-2 mb-0 mb-lg-3">
            <span class="title font-weight-bold">{{i18n.farmAddr}}</span>
          </dt>
          <dd class="description col-12 col-lg-10 mb-4 mb-lg-3">
            <span class="text">{{ farmAddr }}</span>
<!--            <b-button variant="primary" class="ml-4 btn-map" size="sm">-->
<!--              <span class="la la-map mr-1"></span>-->
<!--              <span class="text">지도</span>-->
<!--            </b-button>-->
          </dd>
        </div>
      </li>
          <!--begin::팜 상세 주소-->
      <li class="col-6">
        <div class="row">
          <div class="title col-12 col-lg-4 mb-0 mb-lg-3">
            <span class="title font-weight-bold">{{i18n.farmAddrDetail}}</span>
          </div>
          <div class="description col-12 col-lg-8 mb-4 mb-lg-3">
            <span class="text">{{ farmAddrDetail }}</span>
          </div>
        </div>
      </li>
        <!--begin::팜 지역-->
      <li class="col-6">
        <div class="row">
          <dt class="title col-12 col-lg-4 mb-0 mb-lg-3">
            <span class="title font-weight-bold">{{i18n.farmProvince}}</span>
          </dt>
          <dd class="description col-12 col-lg-8 mb-4 mb-lg-3">
            <span class="text">{{ sidoGb }}</span>
          </dd>
        </div>
      </li>
          <!--begin::팜 통계-->
      <li class="col-12">
        <div class="row">
          <div class="title col-12 mb-1">
            <span class="title font-weight-bold">{{i18n.farmStatistics}}</span>
          </div>
          <div class="description col-12 mb-4 mb-lg-3">
            <div class="text-group-box bg-light border p-6">
              <ul class="text-group row list-unstyled mb-0">
                <template v-for="(item, i) in statsItems">
                  <li class="col-6 text-item" v-bind:key="i">
                    <div class="row">
                      <div class="title col-12 col-lg-5 mb-lg-3">
                        <span class="title font-weight-bold">{{ item.title }}</span>
                      </div>
                      <div class="description col-12 col-lg-7 mb-3 mb-lg-3">
                        <span class="text">{{ item.desc }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="row">
      <!--begin::팜 정보 파일-->
      <div v-if="fileItems && fileItems.length > 0" class="col-12 col-md-6">
        <dl class="text-group row">
          <!--begin::팜 정보 파일-->
          <dt class="title col-12 mb-1">
            <span class="title font-weight-bold">{{i18n.farmInfoFile}}</span>
          </dt>
          <dd class="description col-12 mb-4 mb-lg-3">
            <div class="text-group-box bg-light border p-6">
              <ul class="text-group list-unstyled mb-0">
                <template v-for="(item, i) in fileItems">
                  <li class="text-item row" v-bind:key="i">
                    <div class="title col-12 col-lg-4 mb-lg-3">
                      <span class="title font-weight-bold">{{ item.title }}</span>
                    </div>
                    <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                      <div class="d-flex justify-content-between">
                        <div class="file-name d-flex pr-3 flex-truncate">
                          <span class="text text-truncate">{{ item.desc }}</span>
<!--                          <span class="extension flex-shrink-0">.{{ item.extension }}</span>-->
                        </div>
                        <div class="file-download">
                          <b-button class="btn-icon" size="sm"  variant="primary" @click="downloadFile(item.root)">
                            <i class="la la-download"></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </dd>
        </dl>
      </div>
      <!--end::팜 정보 파일-->
      <!--begin::팜 관리자 정보-->
      <div class="col-12 col-md-6">
        <dl class="text-group row">
          <dt class="title col-12 mb-1">
            <span class="title font-weight-bold">{{i18n.farmManagerInfo}}</span>
          </dt>
          <dd class="description col-12 mb-4 mb-lg-3">
            <div class="text-group-box bg-light border p-6">
              <ul class="text-group list-unstyled mb-0">
                <template v-for="(item, i) in managerItems">
                  <li class="text-item row" v-bind:key="i">
                      <div class="title col-12 col-lg-4 mb-lg-3">
                      <span class="title font-weight-bold">{{ item.title }}</span>
                    </div>
                    <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                        <span class="text text-truncate">{{ item.desc }}</span>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </dd>
        </dl>
      </div>
      <!-- end::팜 관리자 정보 -->
    </div>
  </div>
  <!--end: Popup Smartfarm-->
</template>

<script>

import {getPhoneFormatValue, getValueWithDefault, getValueWithUnit} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'Smartfarm1',
  props: {
    infoA: Object
  },
  data() {
    return {
      i18n: {
        farmAddr: i18n.t('farm.farmAddr'),
        farmAddrDetail: i18n.t('farm.farmAddrDetail'),
        farmProvince: i18n.t('farm.farmProvince'),
        farmStatistics: i18n.t('farm.farmStatistics'),
        farmInfoFile: i18n.t('farm.farmInfoFile'),
        farmManagerInfo: i18n.t('farm.farmManagerInfo'),
        farmAvgProductionPDay: i18n.t('farm.infoA.farmAvgProductionPDay'),
        farmAvgProductionPWeek: i18n.t('farm.infoA.farmAvgProductionPWeek'),
        farmAvgProductionPMonth: i18n.t('farm.infoA.farmAvgProductionPMonth'),
        electricAvgUsage: i18n.t('farm.infoA.electricAvgUsage'),
        waterAvgUsage: i18n.t('farm.infoA.waterAvgUsage'),
        co2AvgUsage: i18n.t('farm.infoA.co2AvgUsage'),        
        farmManagerName: i18n.t('farm.infoA.farmManagerName'),
        farmManagerPhoneNo: i18n.t('farm.infoA.farmManagerPhoneNo'),
        farmManagerEmail: i18n.t('farm.infoA.farmManagerEmail'), 
        won: i18n.t('farm.infoA.won'),
      },
    };
  },
  computed: {
    farmAddr() {
        if(this.infoA) return getValueWithDefault(this.infoA.farmAddr);
        return '-'
    },
    farmAddrDetail() {
      if(this.infoA) return getValueWithDefault(this.infoA.farmAddrDetail);
      return '-'
    },
    sidoGb(){
      if(this.infoA) return getValueWithDefault(this.infoA.sidoGb);
      return '-'
    },
    statsItems() {
      if(this.infoA){
        return [
          { title: i18n.t('farm.infoA.farmAvgProductionPDay'), desc: getValueWithUnit(this.infoA.farmAvgProductionPDay, 'kg', true)},
          { title: i18n.t('farm.infoA.farmAvgProductionPWeek'), desc: getValueWithUnit(this.infoA.farmAvgProductionPWeek, 'kg', true) },
          { title: i18n.t('farm.infoA.farmAvgProductionPMonth'), desc: getValueWithUnit(this.infoA.farmAvgProductionPMonth, 'kg', true) },
          { title: i18n.t('farm.infoA.electricAvgUsage'), desc: getValueWithUnit(this.infoA.electricAvgUsage, i18n.t('farm.infoA.won'), true) },
          { title: i18n.t('farm.infoA.waterAvgUsage'), desc: getValueWithUnit(this.infoA.waterAvgUsage, i18n.t('farm.infoA.won'), true) },
          { title: i18n.t('farm.infoA.co2AvgUsage'), desc: getValueWithUnit(this.infoA.co2AvgUsage, i18n.t('farm.infoA.won'), true) }
        ];
      }
      return []
    },
    fileItems() {
      const items = [];
      if(this.infoA && this.infoA.farmFiles && this.infoA.farmFiles.length > 0){
        this.infoA.farmFiles.forEach(file => {
          items.push({
            title: file.attachType, desc: file.fileNm, root: file.filePath
            // 이부분 도면 한글, 저장할때 부터 영어로 해야함
          })
        });
      }
      return items;
    },
    managerItems() {
      if(this.infoA){
        return [
          { title: i18n.t('farm.infoA.farmManagerName'), desc: getValueWithDefault(this.infoA.farmManagerName)},
          { title: i18n.t('farm.infoA.farmManagerPhoneNo'), desc: getPhoneFormatValue(this.infoA.farmManagerPhoneNo)},
          { title: i18n.t('farm.infoA.farmManagerEmail'), desc: getValueWithDefault(this.infoA.farmManagerEmail)},
        ]
      }
      return [];
    }
  },
  methods: {
    downloadFile(root){
      window.open(root);
    }
  }
};
</script>