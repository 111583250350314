<template>
  <!--begin::Popup Smartfarm-->
  <div class="smartfarm-03 p-6">
    <span class="table-title font-weight-bold">{{i18n.title}}</span>
    <div class="mt-6">
      <v-data-table
          :headers="fields"
          :items="machineItems"
          :items-per-page="10"
          class="elevation-1 farm-data-table"
          no-data-text="No Data"
      >
        <template v-slot:[`item.farmMachineNm`]="{ item }">
          {{ item.farmMachineNm ? item.farmMachineNm : '-' }}
        </template>
        <template v-slot:[`item.machineConnectNm`]="{ item }">
          {{ item.machineConnectNm ? item.machineConnectNm : '-' }}
        </template>
        <template v-slot:[`item.subEquipType`]="{ item }">
          {{ item.subEquipType ? item.subEquipType : '-' }}
        </template>
      </v-data-table>
    </div>
  </div>
  <!--end: Popup Smartfarm-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';
export default {
  name: "popup-smartfarm-05",
  props: {
    machineInfo: Array
  },
  data() {
    return {
      i18n: {
        title: i18n.t('farm.device.title'),
        deviceClassification: i18n.t('farm.device.deviceClassification'),
        deviceType: i18n.t('farm.device.deviceType'),
        subDeviceType: i18n.t('farm.device.subDeviceType'),
        deviceName: i18n.t('farm.device.deviceName'),
        deviceStatus: i18n.t('farm.device.deviceStatus'),
        controllerConnection: i18n.t('farm.device.controllerConnection'),
      },
      fields: [
        { value: 'farmMachineNm', text: i18n.t('farm.device.deviceClassification')},
        { value: 'equipType', text: i18n.t('farm.device.deviceType')},
        { value: 'subEquipType', text: i18n.t('farm.device.subDeviceType')},
        { value: 'machineNm', text: i18n.t('farm.device.deviceName')},
        { value: 'machineSt', text: i18n.t('farm.device.deviceStatus')},
        { value: 'machineConnectNm', text: i18n.t('farm.device.controllerConnection')},
      ]
    };
  },
  computed: {
    machineItems(){
      if(this.machineInfo && this.machineInfo.length > 0){
        return this.machineInfo;
      }
      return [];
    }
  },
  methods: {
  }
};
</script>