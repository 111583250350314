<template>
  <!--begin::Popup Smartfarm-->
  <div class="smartfarm-02 p-6">
    <dl class="text-group row">
        <!--begin::팜 정보 (규모)-->
        <dt class="title col-12 mb-1">
          <span class="title font-weight-bold">{{i18n.farmScale}}</span>
        </dt>
        <dd class="description col-12 mb-4 mb-lg-6">
          <div class="text-group-box bg-light border p-6">
            <ul class="text-group row list-unstyled mb-0">
              <template v-for="(item, i) in scaleItems">
                <li class="col-6 text-item" v-bind:key="i">
                  <div class="row">
                    <div class="title col-12 col-lg-4 mb-lg-3">
                      <span class="title font-weight-bold">{{ item.title }}</span>
                    </div>
                    <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                      <span class="text">{{ item.desc }}</span>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </dd>
        <!--begin::팜 시설 정보-->
        <dt class="title col-12 mb-1">
          <span class="title font-weight-bold">{{i18n.facilityInfo}}</span>
        </dt>
        <dd class="description col-12">
          <div class="text-group-box bg-light border p-6">
            <ul class="text-group row list-unstyled mb-0">
              <template v-for="(item, i) in facilityItems">
                <li class="col-6 text-item" v-bind:key="i">
                  <div class="row">
                    <div class="title col-12 col-lg-4 mb-lg-3">
                      <span class="title font-weight-bold">{{ item.title }}</span>
                    </div>
                    <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                      <span class="text">{{ item.desc }}</span>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </dd>
    </dl>
  </div>
  <!--end: Popup Smartfarm-->
</template>

<script>
import {getValueWithDefault, getValueWithUnit} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';
export default {
  name: "Smartfarm2",
  props: {
    infoB: Object
  },
  data() {
    return {
      i18n: {
        farmScale: i18n.t('farm.infoB.farmScale'),
        facilityInfo: i18n.t('farm.infoB.facilityInfo'),

        farmLocation: i18n.t('farm.infoB.farmLocation'),
        farmLocationGb: i18n.t('farm.infoB.farmLocationGb'),
        farmArea: i18n.t('farm.infoB.farmArea'),
        farmCultivateArea: i18n.t('farm.infoB.farmCultivateArea'),
        envCtrlCount: i18n.t('farm.infoB.envCtrlCount'),
        nutCtrlCount: i18n.t('farm.infoB.nutCtrlCount'),
        layerCount: i18n.t('farm.infoB.layerCount'),
        rowCount: i18n.t('farm.infoB.rowCount'),
        rackCount: i18n.t('farm.infoB.rackCount'),
        bedCount: i18n.t('farm.infoB.bedCount'),
        bedStandards: i18n.t('farm.infoB.bedStandards'),
        seedRackCount: i18n.t('farm.infoB.seedRackCount'),
        transplantRackCount: i18n.t('farm.infoB.transplantRackCount'),
        lastfeedRackCount: i18n.t('farm.infoB.lastfeedRackCount'),
        rackTypes: i18n.t('farm.infoB.rackTypes'),
        potCount: i18n.t('farm.infoB.potCount'),
        pyeong: i18n.t('farm.infoB.pyeong'),
        units: i18n.t('farm.infoB.units')
      },
    };
  },
  computed: {
    scaleItems() {
      if(this.infoB){
        return [
          { title: i18n.t('farm.infoB.farmLocation'), desc: getValueWithDefault(this.infoB.farmLocation) },
          { title: i18n.t('farm.infoB.farmLocationGb'), desc: getValueWithDefault(this.infoB.farmLocationGb) },
          { title: i18n.t('farm.infoB.farmArea'), desc: getValueWithUnit(this.infoB.farmArea, i18n.t('farm.infoB.pyeong'), true) },
          { title: i18n.t('farm.infoB.farmCultivateArea'), desc: getValueWithUnit(this.infoB.farmCultivateArea, i18n.t('farm.infoB.pyeong'), true) },
          { title: i18n.t('farm.infoB.envCtrlCount'), desc: getValueWithUnit(this.infoB.envCtrlCount, i18n.t('farm.infoB.units'), true) },
          { title: i18n.t('farm.infoB.nutCtrlCount'), desc: getValueWithUnit(this.infoB.nutCtrlCount, i18n.t('farm.infoB.units'), true) }
        ]
      }
      return [];
    },
    facilityItems() {
      if(this.infoB){
        return [
          { title: i18n.t('farm.infoB.layerCount'), desc: getValueWithUnit(this.infoB.layerCount, i18n.t('farm.infoB.units'), true) },
          { title: i18n.t('farm.infoB.rowCount'), desc: getValueWithUnit(this.infoB.rowCount, i18n.t('farm.infoB.units'), true) },
          { title: i18n.t('farm.infoB.rackCount'), desc: getValueWithUnit(this.infoB.rackCount, i18n.t('farm.infoB.units'), true) },
          { title: i18n.t('farm.infoB.bedCount'), desc: getValueWithUnit(this.infoB.bedCount, i18n.t('farm.infoB.units'),true) },
          { title: i18n.t('farm.infoB.bedStandards'), desc: getValueWithDefault(this.infoB.bedStandards) },
          { title: i18n.t('farm.infoB.seedRackCount'), desc: getValueWithUnit(this.infoB.seedRackCount, i18n.t('farm.infoB.units'), true) },
          { title: i18n.t('farm.infoB.transplantRackCount'), desc: getValueWithUnit(this.infoB.transplantRackCount, i18n.t('farm.infoB.units'), true) },
          { title: i18n.t('farm.infoB.lastfeedRackCount') , desc: getValueWithUnit(this.infoB.lastfeedRackCount, i18n.t('farm.infoB.units'), true) },
          { title: i18n.t('farm.infoB.rackTypes'), desc: this.getRackTypes() },
          { title: i18n.t('farm.infoB.potCount'), desc: getValueWithUnit(this.infoB.potCount, i18n.t('farm.infoB.units'), true) }
        ]
      }
      return [];
    }
  },
  methods: {
    getRackTypes(){
      const rackTypeList = [];
      if(this.infoB){
        for(let i = 1; i <=5; i++){
          if(this.infoB['rackType' + i]){
            rackTypeList.push(this.infoB['rackType' + i]);
          }
        }
      }
      if(rackTypeList.length) return rackTypeList.join(', ');
      return '-';
    }
  }
};
</script>