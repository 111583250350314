<template>
  <!--begin::Popup Smartfarm-->
  <div class="smartfarm-03 p-6">
    <span class="table-title font-weight-bold">{{i18n.title}}</span>
    <div class="mt-6">
        <v-data-table
            :headers="fields"
            :items="connectItems"
            :items-per-page="10"
            class="elevation-1 farm-data-table"
            no-data-text="No Data"
        ></v-data-table>

<!--      <b-table -->
<!--        responsive-->
<!--        :items="items"-->
<!--        :fields="fields"-->
<!--        head-variant="light"-->
<!--        class="table-vertical-center"-->
<!--      >-->
<!--      <template #table-colgroup="scope">-->
<!--        <col-->
<!--          v-for="field in scope.fields"-->
<!--          :key="field.key"-->
<!--          :style="{ width: field.key === 'idx' ? '70px' : 'auto' }"-->
<!--        >-->
<!--      </template>-->
<!--      </b-table>-->



    </div>
  </div>
  <!--end: Popup Smartfarm-->
</template>

<script>

import {getValueWithDefault} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';
export default {
  name: "Smartfarm3",
  props: {
    connectInfo: Array
  },
  data() {
    return {
      i18n: {
        title: i18n.t('farm.controller.title'),
        numbering: i18n.t('farm.controller.numbering'),
        controllerName: i18n.t('farm.controller.controllerName'),
        controllerCode: i18n.t('farm.controller.controllerCode'),
        controllerType: i18n.t('farm.controller.controllerType'),
        culturalType: i18n.t('farm.controller.culturalType'),
        systemId: i18n.t('farm.controller.systemId'),
      },
      fields: [
        { value: 'idx', text: i18n.t('farm.controller.numbering')},
        { value: 'connectNm', text: i18n.t('farm.controller.controllerName')},
        { value: 'connectCd', text: i18n.t('farm.controller.controllerCode')},
        { value: 'envCtrlType', text: i18n.t('farm.controller.controllerType')},
        { value: 'growType', text: i18n.t('farm.controller.culturalType')},
        { value: 'systemId', text: i18n.t('farm.controller.systemId')},
      ],
    };
  },
  computed: {
    connectItems() {
      const items = [];
      if(this.connectInfo){
        this.connectInfo.forEach((connect, idx) => {
          items.push({
            'idx': (idx + 1), 'connectNm': connect.connectNm, 'connectCd' : getValueWithDefault(connect.connectCd),
            'envCtrlType': connect.envCtrlType, 'growType': getValueWithDefault(connect.growType),
            'systemId': (getValueWithDefault(connect.systemId) ? getValueWithDefault(connect.systemId) : '-')
          });
        });
      }
      return items;
    }
  },
  methods: {
  }
};
</script>